import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import {
  Container, Row, Col, Button,
} from 'react-bootstrap';

import './exercise-list.scss';

const ExerciseList = ({ acfExerciseContent }) => {
  useEffect(() => {
    setActiveExercise(acfExerciseContent.exerciseContent[0].name);
  }, []);
  const [activeExercise, setActiveExercise] = useState();

  const handleOpenModal = (exercise) => {
    setActiveExercise(exercise);
  };

  return (
    <Container className="exercise-list h-100">
      <Row className="h-100 align-items-center">
        <Col xs={4} className="d-flex flex-column h-100">
          {
            (acfExerciseContent)
              ? acfExerciseContent.exerciseContent.map((item, i) => (
                <button
                  key={`item_${i.toString()}`}
                  type="button"
                  className={`mb-3 exercise-btn ${item.name === activeExercise ? 'active' : ''}`}
                  onClick={() => handleOpenModal(item.name)}
                >
                  {item.name}
                </button>
              ))
              : ''
           }
        </Col>
        <Col xs={8} className="h-100">
          {
            (acfExerciseContent)
              ? acfExerciseContent.exerciseContent.map((item, i) => (
                (item.name === activeExercise)
                  ? (
                    <div
                      className="h-100"
                      key={`item_${i.toString()}`}
                    >
                      <div className="exercise-container">
                        {parse(item.description)}
                      </div>
                      <div className="exercise-container">
                        {parse(item.time)}
                      </div>
                      <div className="exercise-container">
                        {parse(item.materials)}
                      </div>
                      <div className="exercise-container">
                        {parse(item.preparation)}
                      </div>
                      <div className="exercise-container">
                        {parse(item.procedure)}
                      </div>
                      {
                        item.questions
                          ? (
                            <div className="exercise-container">
                              {parse(item.questions)}
                            </div>
                          )
                          : ''
                      }
                      {
                        item.additionalInformation
                          ? (
                            <div className="exercise-container">
                              {parse(item.additionalInformation)}
                            </div>
                          )
                          : ''
                      }
                      {
                        item.references
                          ? (
                            <div className="exercise-container">
                              {parse(item.references)}
                            </div>
                          )
                          : ''
                      }
                      {
                        acfExerciseContent.chapterLink && acfExerciseContent.chapterLinkLabelText
                          ? (
                            <a
                              href={acfExerciseContent.chapterLink ? acfExerciseContent.chapterLink.uri : ''}
                              className="btn btn-primary d-block m-auto back-to-chapter-btn"
                            >
                              {acfExerciseContent.chapterLinkLabelText ? acfExerciseContent.chapterLinkLabelText : ''}
                            </a>
                          ) : ''
                      }
                    </div>
                  )
                  : ''
              ))
              : ''
            }
        </Col>
      </Row>
    </Container>
  );
};

export default ExerciseList;
